import * as Sentry from '@sentry/react';
import { isLocalhost } from '_core';
import * as coreEntities from '_core/store/index';
import { getLemonadeTranslates } from 'app/utils/lemonade-translates';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useMintLessonEditorPlusV1Lib, useMintLessonVisorPlusV1Lib } from 'react-mint-editor';
import { useSelector } from 'react-redux';

const InitsContainer = () => {
  const currentUser = useSelector((state) => coreEntities.auth.selectors.getUser(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));
  const { api: apiEditor } = useMintLessonEditorPlusV1Lib();
  const { api: apiVisor } = useMintLessonVisorPlusV1Lib();

  useEffect(() => {
    if (language === 'pt') require('moment/locale/pt.js');
    else if (language === 'en') moment.locale('en');
    else require('moment/locale/es.js');
  }, [language]);

  useEffect(() => {
    if (!isLocalhost()) {
      Sentry.init({
        dsn: 'https://c583e7250991f783148988b55836f209@o4505759437029376.ingest.us.sentry.io/4506257572626432',
        integrations: [
          Sentry.browserTracingIntegration(),
          // Sentry.replayIntegration(),
          // Sentry.replayCanvasIntegration()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event, hint) {
          // Accede al mensaje de error original
          const errorMessage = hint?.originalException?.message;

          // Comprueba si el mensaje de error es el que quieres ignorar
          if (errorMessage?.includes('cannot be parsed as a URL') || errorMessage?.includes('Failed to fetch') || errorMessage?.includes('$(iframe).attr')) {
            // No enviar el evento a Sentry
            return null;
          }

          // Devuelve el evento si no coincide con el filtro
          return event;
        },
      });
    }

    getLemonadeTranslates(apiEditor, apiVisor);
  }, []);

  return <></>;
};

export default InitsContainer;
